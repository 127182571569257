/* You can add global styles to this file, and also import other style files */
// @import "~bootstrap/scss/bootstrap.scss";
@import "~bootstrap/scss/_functions.scss";
@import "~bootstrap/scss/_variables.scss";
@import "~bootstrap/scss/_mixins.scss";
@import "~bootstrap/scss/_root.scss";
@import "~bootstrap/scss/_reboot.scss";
@import "~bootstrap/scss/_type.scss";
@import "~bootstrap/scss/_images.scss";
@import "~bootstrap/scss/_code.scss";
@import "~bootstrap/scss/_grid.scss";
@import "~bootstrap/scss/_tables.scss";
@import "~bootstrap/scss/_dropdown.scss";
@import "~bootstrap/scss/_button-group.scss";
@import "~bootstrap/scss/_nav.scss";
@import "~bootstrap/scss/_navbar.scss";
@import "~bootstrap/scss/_list-group.scss";
@import "~bootstrap/scss/_tooltip.scss";
@import "~bootstrap/scss/_utilities.scss";
@import "./app/project-common/styles/toaster.scss";
 
@import "./styles/helpers.scss";
@import "partials/mixins";

body {
  height: 100vh;
  overflow: hidden;
}

.snakbar-color {
  background-color: var(--kl-primary-700);
  color: var(--app-white);
}

.ag-theme-alpine .ag-header-row {
  font-weight: normal;
}

button {
  outline: none !important;
}

// html,
// body {
//   height: 100%;
// }

// body {
//   margin: 0;
//   font-family: var(--app-font-family);
//   text-rendering: optimizeLegibility;
//   font-size: 1em;
//   -webkit-tap-highlight-color: transparent;

//   &.other-language {
//     letter-spacing: 0.4px;
//     font-size: 0.94em;
//     * {
//       line-height: 1.5;
//     }
//   }

//   @media only screen and (max-width: 1280px) and (min-width: 599px)  {
//     font-size: 0.95em !important;
//   }

//   @media screen and (max-width: 599px) {
//     font-size: 0.9em !important;
//   }
// }

// h1,
// h2,
// h3,
// h4,
// div,
// p,
// a,
// span {
//   font-family: var(--app-font-family) !important;
// }

span.ag-icon {
  font-family: var(--ag-grid-font) !important;
}

.ag-radio-button-input-wrapper {
  font-family: var(--ag-grid-font) !important;
}

.mat-button.mat-warn,
.mat-icon-button.mat-warn,
.mat-stroked-button.mat-warn {
  color: var( --app-theme-accent);
}

*:disabled,
*:-moz-user-disabled,
*::-webkit-validation-bubble-message {
  cursor: not-allowed;
}

/* Adding material fonts instead of making a separate call for material icons */
/* fallback */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/materialicons/v41/flUhRq6tzZclQEJ-Vdg-IuiaDsNcIhQ8tQ.woff2)
    format("woff2");
  src: url('/assets/fonts/material/MaterialIcons-Regular.eot'); /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"),
    url('/assets/fonts/material/MaterialIcons-Regular.woff2') format("woff2"),
    url('/assets/fonts/material/MaterialIcons-Regular.woff') format("woff"),
    url('/assets/fonts/material/MaterialIcons-Regular.ttf') format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: "liga";

  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;

  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;

  /* Support for IE. */
  font-feature-settings: "liga";
}

// container for whole app

.medium-screen {
  display: none !important;
  @media only screen and (max-width: 1024px) {
      display: block !important;
  }
}

.large-screen {
  display: block !important;
  @media only screen and (max-width: 1024px) {
      display: none !important;
  }
}

.small-screen {
  display: none !important;
  @media only screen and (max-width: 1024px) and (min-width: 599px) {
    display: block !important;
  }
}

.small-screen-hide {
  display: block !important;
  @media only screen and (max-width: 1024px) and (min-width: 599px) {
    display: none !important;
  }
}

.app-container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 10px;
  box-sizing: border-box;

  @media screen and (max-width: 1400px) and (min-width: 1280px) {
      max-width: 1180px;
  }

  @media only screen and (max-width: 1280px) and (min-width: 1024px) {
    max-width: 100%;
  }

  @media screen and (max-width: 1024px) and (min-width: 599px) {
    max-width: 100%;
    padding: 0 10px;
  }

  @media screen and (max-width: 599px) {
    max-width: 100%;
    padding: 0;
  }
}

.overridden-bootstrap-cls {
  .m-2 {
      margin: .5rem !important;
  }

  .mt-2 {
      margin-top: .5rem !important;
  }

  .mt-3 {
      margin-top: 1rem !important;
  }

  .px-3 {
      padding-left: 1rem !important;
      padding-right: 1rem !important;
  }

  .py-2 {
      padding-bottom: .5rem !important;
      padding-top: .5rem !important;
  }

  .mr-1 {
      margin-right: .25rem!important;
  }

  .p-2 {
      padding: .5rem !important;
  }

  .p-0 {
      padding: 0 !important;
  }

  .px-2 {
    padding-left: .5rem !important;
    padding-right: .5rem !important;
  }

  .pt-2 {
    padding-top: .5rem !important;
  }

  .mb-2 {
    margin-bottom: .5rem!important;
  }

  .mx-2 {
    margin-left: .5rem !important;
    margin-right: .5rem !important;
  }
}

* {
  outline: none;
  line-height: 1.3;
}
.z-depth-1 {
  -webkit-box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
}
.float-right {
  float: right;
}
.cursor-pointer {
  cursor: pointer;
}
.m0 {
  margin: 0;
}
.p0 {
  padding: 0px !important;
}
.pl0 {
  padding-left: 0px !important;
}
.pr0 {
  padding-right: 0 !important;
}
.mt0 {
  margin-top: 0;
}
.mt10 {
  margin-top: 10px;
}
.mb25 {
  margin-bottom: 25px;
}
.mb10 {
  margin-bottom: 10px;
}
.mb0 {
  margin-bottom: 0px !important;
}
.mt-50 {
  margin-top: 50px;
}
.pl-20 {
  padding-left: 20px;
}
footer .fa {
  padding: 10px;
}
.pos-relative {
  position: relative;
}
/* a {
  color: inherit;
} */
.disable {
  pointer-events: none;
}
.rotate {
  transform: rotate(-37deg);
}


.fixed {
  z-index: 1000;
  position: fixed;
}
.content .btn {
  margin-top: 10px;
  box-shadow: none;
  /*padding: 2px 8px 5px;*/
  border-radius: 4px;
}

/* media quires */

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .card {
    display: block;
    width: 100%;
    margin: 20px 20px 0 0;
    padding: 20px;
  }
  .slider {
    padding-top: 50%;
    height: 0px !important;
    .slides {
      height: auto !important;
    }
  }
  .slider .slides li .caption {
    top: 15%;
  }
  .slider .slides li img {
    background-position: unset;
  }
  h2 {
    line-height: 20px !important;
  }
  .ph10-sm {
    padding: 0px 10px !important;
  }

  .field mat-form-field {
    margin-top: 6px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .card {
    width: 20rem;
    margin: 20px 20px 24px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .card {
    width: 18rem;
    margin: 20px 18px 24px;
  }
  .slider,
  .slides {
    height: 350px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .card {
    width: 20rem;
    margin: 20px 25px 24px;
  }
  .slider,
  .slides {
    height: 350px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .card {
    width: 18rem;
    margin: 0px 20px 20px;
  }
  .slider,
  .slides {
    height: 350px !important;
  }
}
@media only screen and (min-width: 1300px) {
  .card {
    width: 17rem;
    margin: 20px 30px 24px 0px;
  }
  .slider,
  .slides {
    height: 350px !important;
  }
}

@media only screen and (min-width: 1370px) {
  .card {
    width: 18rem;
    margin: 20px 20px 20px 0px;
  }
  .slider,
  .slides {
    height: 350px !important;
  }
}
.fixed {
  z-index: 1000;
  position: fixed;
}
.col-pd {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}
.grey-text {
  color: #74748e;
}

.product-name {
  display: flex;
  align-items: center;
}

.spinner {
  height: 100%;
  position: relative;
  width: 100%;
  background: #0000006b;
  z-index: 99999;
}
.spinner-x {
  height: 100%;
  width: 100%;
  bottom: 10px;
  z-index: 99999;
}

.spinner mat-spinner {
  left: 50%;
  top: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}
.tabs .indicator {
  background-color: #ab2329 !important;
}

.no_wrap > .formated_text > .text_wrapping {
  word-break: normal !important;
  text-align: left !important;
}

.font_size_16 > .formated_text > .text_wrapping * {
  font-size: 16px !important;
}

.text_wrapping {
  word-break: keep-all;
  text-align: justify;
}

.no_wrap > .formated_text > .text_wrapping a {
  color: #039be5;
}

/* Progress spinner ends */

.all-categories {
  flex: 1;
  overflow: hidden;
}


.mango-panel-cls {

  &.mat-autocomplete-panel {
      top: 1px !important;
      left: 1px !important;
  }
  
}

.kl-dialog {
  .mat-dialog-container {
    padding: 0 !important;
  }
}

@media only screen and (max-width: 520px) {
  .my-container {
    width: 100% !important;
  }
}

@media only screen and (max-width: 992px) {
  .menu-bar-section {
    transform: translateY(-100%);
  }
  .web-logo {
    margin: 0 !important;
    flex: 1;
  }
  .dropdown-menu {
    padding-left: 10px;
  }
  .dropdown-menu i {
    text-align: right;
    cursor: pointer;
    color: white;
  }
  .search-icon {
    height: 33px;
    width: 33px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    padding-right: 10px;
    color: white;
    cursor: pointer;
    position: relative;
  }
  .main-cart {
    margin-left: 0px !important;
    margin-right: 15px !important;
  }
}
@media screen and (max-width: 2000px) and (min-width: 900px) {
  .row .col.m3 {
    width: 21%;
  }

  .row .col.m9 {
    width: 79%;
  }
}

/* Container queries */

.my-container {
  margin: 0 auto;
  max-width: 2000px;
  // width: 1280px;
}
@media only screen and (max-width: 1160px) {
  .my-container {
    width: 90%;
  }
}
@media only screen and (max-width: 1060px) {
  .my-container {
    width: 95%;
  }
}
@media only screen and (max-width: 590px) {
  .my-container {
    width: 100%;
  }
  .collection .collection-item.avatar {
    min-height: 68px;
  }
  .card-panel.row.ph10-sm {
    margin-top: 0px !important;
  }
}

h6 .material-icons {
  position: relative;
  top: 10px;
}

.single-line-text {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Alerts css starts */

.alert {
  padding: 15px;
  border-radius: 0 4px 4px 0;
  color: white;
  letter-spacing: 1.25px;
  border-left: 8px solid;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.alert.success {
  background: #0f834d;
  border-left-color: rgba(0, 0, 0, 0.15);
}
.alert.danger {
  background: #ff6347c7;
  border-left-color: rgba(0, 0, 0, 0.15);
}

/* Alerts css ends */


.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}
/* d3 chart css ends*/

/* image-view styles starts */
.full-width-dialog {
  max-width: 100vw !important;
  max-height: 100%;

  .mat-dialog-container {
    background: rgba(0, 0, 0, 0.863);
    color: rgba(255, 255, 255, 0.87);
    overflow: hidden;
  }
}

/* Mobile devices style */
@media (max-width: 768px) {
  .next-btn,
  .prev-btn {
    display: none;
  }
}
/* image-view styles ends */

::ng-deep {
  .cdk-overlay-pane {
    transform: translate(-25%, 25%) !important;
  }
}

.one-line {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.loader {
  padding: 15px;
  height: auto;
  background: #f8f8f8;
  position: relative;
  overflow: hidden;
  border-radius: 3px;
  @media screen and (max-width: 599px) {
    background: #e0e0e0;
  }
  .elm {
    background: #c3c3c3;
    border-radius: 3px;
  }
  ._1 {
    width: 100%;
  }
  ._2 {
    width: 85%;
  }
  ._3 {
    width: 75%;
  }
  ._4 {
    width: 65%;
  }
  ._5 {
    width: 50%;
  }
  ._6 {
    width: 20%;
  }
  ._7 {
    width: 5%;
  }
  .img-ht {
    height: 20px;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 0.48),
      rgba(0, 0, 0, 0)
    );
    z-index: 2;
    animation: load-anim 1s ease-in infinite;
  }
  @keyframes load-anim {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(100%);
    }
  }
}

.hidden {
  visibility: hidden;
  transform: translateY(10px);
  opacity: 0;
  transition: all 0.3s ease-in-out;
}

.visible {
  visibility: visible;
  opacity: 1;
  transform: translateY(0px);
}

@media screen and (max-width: 599px) {
  snack-bar-container {
    height: 48px;
    display: flex !important;
    align-items: center;
    justify-content: center;
    // color: #ffc107 !important;
    font-weight: 500;
  }
}

.mat-simple-snackbar-action {
  span.mat-button-wrapper {
    color: var(--app-white );
    font-weight: 1000;
  }
}
/* .ng-lazyloaded {
  animation: fadein 0.5s;
} */

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

#placeBtnInBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}
/* .dark-txt {
  color: #4c4c4c;
} */
.white-txt {
  color: white;
}

@media (min-width: 801px) {

  ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: white;
    &:hover {
      background: #eee;
    }
  }
  
  ::-webkit-scrollbar {
    width: 8px;
    background-color: white;
  }
  
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #888;
  }
}

input[type="number"] {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0;
  }
}

@keyframes fadeInUp {
  from {
    transform: translate3d(0, 20px, 0);
    opacity: 0;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

button[color="primary"] {
  color: white;
}

@keyframes slideInDown {
  from {
    transform: translate3d(0, -100%, 0);
    visibility: visible;
  }

  to {
    transform: translate3d(0, 0, 0);
  }
}

.slideInDown {
  animation-name: slideInDown;
  animation-duration: 0.4s;
}

.pulse-affect {
  animation: pulseAffect 0.4s ease-in-out infinite alternate;
}

@keyframes pulseAffect {
  100% {
    opacity: 0.2;
  }
}

a {
  cursor: pointer;
  color: inherit;
  &:hover {
    color: inherit;
    text-decoration: none;
  }
}


// TO fix material icons breaking for other languages
mat-icon.material-icons {
  line-height: 1 !important;
}

.addToCart {
  font-weight: 500;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: font(14);
            background: #f24f50;
            color: white;
            margin-top: 1px;
            text-transform: uppercase;
            letter-spacing: 0.4px;
            border: none;
            transition: all 0.2s ease-in-out;
            &:hover {
                opacity: 0.8;
            }
            &.border-btn {
                background: white;
                border: 2px solid #ffb300;
                padding: 2px 5px;
            }
            &:disabled {
                background: #76899c;
                pointer-events: none;
            }
            @media screen and (max-width: 599px) {
                padding: 4px 6px;
            }
}

@font-face {
  font-family: 'icomoon';
  src:  url('assets/fonts/custom/icomoon.eot?labyhb');
  src:  url('assets/fonts/custom/icomoon.eot?labyhb#iefix') format('embedded-opentype'),
    url('assets/fonts/custom/icomoon.ttf?labyhb') format('truetype'),
    url('assets/fonts/custom/icomoon.woff?labyhb') format('woff'),
    url('assets/fonts/custom/icomoon.svg?labyhb#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-farmer:before {
  content: "\e900";
}

.icon-pin:before {
  content: "\e901";
}

.icon-delivery:before {
  content: "\e902";
}

.swal2-popup {
  h2 {
    line-height: 1 !important;
  }
}

.swal2-title {
  font-size: 18px !important;
}

.swal2-styled.swal2-confirm, .swal2-styled.swal2-deny, .swal2-icon.swal2-warning {
  font-size: 13px !important;
}