/*
 * MATERIAL theme
 */
#toasta {
  position: fixed;
  z-index: 999999;
  /* Positioning */
}

  #toasta .close-button:focus {
    outline: 0;
  }

  #toasta.toasta-position-top-left {
    top: 12px;
    left: 12px;
  }

  #toasta.toasta-position-top-right {
    top: 12px;
    right: 12px;
  }

  #toasta.toasta-position-bottom-right {
    bottom: 12px;
    left: 12px;
  }

  #toasta.toasta-position-bottom-left {
    bottom: 12px;
    left: 12px;
  }

  #toasta.toasta-position-top-fullwidth {
    top: 12px;
    width: 100%;
  }

  #toasta.toasta-position-bottom-fullwidth {
    bottom: 12px;
    width: 100%;
  }

    #toasta.toasta-position-top-fullwidth .toast,
    #toasta.toasta-position-bottom-fullwidth .toast {
      width: 96%;
      margin-left: auto;
      margin-right: auto;
    }

  #toasta.toasta-position-top-center {
    top: 12px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  #toasta.toasta-position-bottom-center {
    bottom: 12px;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  #toasta.toasta-position-center-center {
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, 0%);
    transform: translate(-50%, 0%);
  }

  #toasta .toast {
    cursor: pointer;
    margin: 0 0 6px;
    padding: 0;
    width: 285px;
    height: 65px;
    display: table;
    background-size: 24px 24px;
    background-position: 15px center;
    background-repeat: no-repeat;
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
    opacity: 0.9;
    position: relative;
  }

    #toasta .toast .close-button {
      padding: 0;
      cursor: pointer;
      background: transparent;
      border: 0;
      -webkit-appearance: none;
      position: absolute;
      right: 10px;
      top: 3px;
      opacity: 0.5;
      filter: alpha(opacity=50);
    }

      #toasta .toast .close-button:hover,
      #toasta .toast .close-button:focus {
        text-decoration: none;
        cursor: pointer;
        opacity: 1;
        filter: alpha(opacity=100);
      }

    #toasta .toast .toast-text {
      padding: 5px 20px 5px 60px;
      display: table-cell;
      vertical-align: middle;
    }

      #toasta .toast .toast-text .toast-title {
        font-weight: bold;
      }

      #toasta .toast .toast-text a:hover,
      #toasta .toast .toast-text label:hover {
        text-decoration: none;
      }

    /***************
 MATERIAL THEME
 **************/

    #toasta .toast.toasta-theme-material {
      font-family: 'Roboto', sans-serif !important;
      font-size: 12px;
      background-color: #323232;
    }

      #toasta .toast.toasta-theme-material .close-button:after {
        content: 'x';
        color: #ffffff;
        font-size: 17px;
        font-weight: 100;
      }

      #toasta .toast.toasta-theme-material .toast-text {
        color: #ffffff;
      }

        #toasta .toast.toasta-theme-material .toast-text .toast-title {
          text-transform: uppercase;
          font-size: 13px;
        }

        #toasta .toast.toasta-theme-material .toast-text .toast-msg {
          /**/
        }

        #toasta .toast.toasta-theme-material .toast-text a,
        #toasta .toast.toasta-theme-material .toast-text label {
          color: #ffffff;
        }

          #toasta .toast.toasta-theme-material .toast-text a:hover,
          #toasta .toast.toasta-theme-material .toast-text label:hover {
            color: #f2f2f2;
          }

      #toasta .toast.toasta-theme-material.toasta-type-default {
        background-image: url('../../../assets/images/toaster-default.png') !important;
      }

      #toasta .toast.toasta-theme-material.toasta-type-info {
        background-image: url('../../../assets/images/toaster-info.png') !important;
      }

      #toasta .toast.toasta-theme-material.toasta-type-wait {
        background-image: url('../../../assets/images/toaster-wait.gif') !important;
      }

      #toasta .toast.toasta-theme-material.toasta-type-error {
        background-image: url('../../../assets/images/toaster-error.png') !important;
      }

      #toasta .toast.toasta-theme-material.toasta-type-success {
        background-image: url('../../../assets/images/toaster-sucess.png') !important;
      }

      #toasta .toast.toasta-theme-material.toasta-type-warning {
        background-image: url('../../../assets/images/toaster-warn.png') !important;
      }
