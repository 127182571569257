// @for $i from 1 through 4
//   .#{$class-slug}-#{$i}
//     width: 60px + $i

// $i: 0;
// @while $i < 100 {
//   .p-#{$value}
//   $i: $i + 2;
// }
@for $i from 1 through 9 {
  $value: $i * 100;

  .fw-#{$value} {
    font-weight: #{$value} !important;
  }
  
}
@for $i from 0 through 50 {
  $value: $i;

  .p-#{$value} {
    padding: #{$value}px !important;
  }

  .pl-#{$value} {
    padding-left: #{$value}px !important;
  }

  .pr-#{$value} {
    padding-right: #{$value}px !important;
  }

  .pt-#{$value} {
    padding-top: #{$value}px !important;
  }

  .pb-#{$value} {
    padding-bottom: #{$value}px !important;
  }

  .px-#{$value} {
    padding-left: #{$value}px !important;
    padding-right: #{$value}px !important;
  }

  .py-#{$value} {
    padding-top: #{$value}px !important;
    padding-bottom: #{$value}px !important;
  }

  .m-#{$value} {
    margin: #{$value}px !important;
  }

  .ml-#{$value} {
    margin-left: #{$value}px !important;
  }

  .mr-#{$value} {
    margin-right: #{$value}px !important;
  }

  .mt-#{$value} {
    margin-top: #{$value}px !important;
  }

  .mb-#{$value} {
    margin-bottom: #{$value}px !important;
  }

  .mx-#{$value} {
    margin-left: #{$value}px !important;
    margin-right: #{$value}px !important;
  }

  .my-#{$value} {
    margin-top: #{$value}px !important;
    margin-bottom: #{$value}px !important;
  }

  .m--#{$value} {
    margin: -#{$value}px !important;
  }

  .ml--#{$value} {
    margin-left: -#{$value}px !important;
  }

  .mr--#{$value} {
    margin-right: -#{$value}px !important;
  }

  .mt--#{$value} {
    margin-top: -#{$value}px !important;
  }

  .mb--#{$value} {
    margin-bottom: -#{$value}px !important;
  }

  .mx--#{$value} {
    margin-left: -#{$value}px !important;
    margin-right: -#{$value}px !important;
  }

  .my--#{$value} {
    margin-top: -#{$value}px !important;
    margin-bottom: -#{$value}px !important;
  }

  .br-#{$value} {
    border-radius: #{$value}px !important;
  }
}

@for $i from 0 through 50 {
  $size: $i/16;

  .font-#{$i} {
    font-size: #{$size}em !important;
  }
}

@for $i from 0 through 10 {
  $value: $i * 10;

  // .h-#{$value}p {
  //   height: #{$value}% !important;
  // }

  // .w-#{$value}p {
  //   width: #{$value}% !important;
  // }
}



@mixin line-truncate($line) {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  white-space: normal;
  -webkit-line-clamp: $line;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}